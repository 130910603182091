<template>
  <div class="PointsMall container">
    <!-- <div class="left"></div> -->
    <div class="right">
      <div class="banner">
        <img src="@/assets/images/pointBanner.png">
      </div>
      <div class="goodsList">
        <ul>
          <li v-for="item in list"  @click="goodsDetail(item)">
            <img :src="item.goodsPic">
            <div class="goodsName">{{item.goodsName}}</div>
            <div class="goodsPrice">
              <div class="price">{{item.consumerPointPrice}}积分</div>
              <div class="read"> <span>{{item.soldCount}}人</span>兑换过</div>
            </div>
          </li>
        </ul>
      </div>
       <Row class="mb20">
        <Page
          class="mt20 mr20 fr"
          size="small"
          show-elevator
          show-sizer
          show-total
          :total="total"
          :page-size="searchForm.pageSize"
          :current.sync="searchForm.page"
          @on-change="search"
          @on-page-size-change="
            (pageSize) => {
              (searchForm.pageSize = pageSize), search();
            }
          "
        />
      </Row>
    </div>
  </div>
</template>

<script>
import api from '@/util/api'
export default {
   name: "favorite",
  components: {},
  data() {
    return {
      favShow: false,
      indexId: null,
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      list:[],
      favoritesList: [],
    };
  },
  mounted() {
    this.search();
    
  },
  methods:{
    search(){
      let searchForm = this.searchForm
      api.pointPointGoodsPage(searchForm,res=>{
        this.list = res.resultList
        this.total = res.resultCount
      })
    },
    // goodsDetail(item) {
    //   sessionStorage.setItem("itemDetails", JSON.stringify(item));
    //   this.$router.push({ path: "/GoodsDetail" });
    // },
  },
}
</script>

<style lang="less" scoped>
.PointsMall {
  display: flex;
  // .left {
  //   width: 220px;
  //   height: 467px;
  //   background: #ffffff;
  //   border-radius: 2px;
  //   border: 2px solid #299654;
  //   margin-right: 20px;
  // }
  .right {
    width: 1120px;
    // border:1px solid red;
    .banner {
      img {
        width: 1120px;
        height: 200px;
      }
    }
    .goodsList {
      margin-top: 16px;
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        li {
          background: #ffffff;
          margin-right: 12px;
          margin-bottom: 12px;
          cursor: pointer;
          &:nth-child(3n) {
            margin-right: 0;
          }
          img {
            width: 291px;
            height: 291px;
            border-radius: 2px 2px 0px 0px;
          }
          .goodsName {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 25px;
            letter-spacing: 1px;
            padding: 12px;
          }
          .goodsPrice {
            display: flex;
            padding: 0 12px 12px;
            align-items: center;
            .price {
              height: 33px;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e64747;
              line-height: 33px;
              letter-spacing: 1px;
            }
            .read {
              height: 17px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #838383;
              line-height: 17px;
              margin-left: auto;
              span {
                color: #299654;
              }
            }
          }
        }
      }
    }
  }
}
</style>